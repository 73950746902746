import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
//import CircularProgress from '@mui/material/CircularProgress';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
//import LinearProgress from '@mui/material/LinearProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import './App.css';

export default function App(props) {

	const [progress, setProgress] = React.useState(0);
	React.useEffect(() => {
		const timer = setInterval(() => {
			//setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
			setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : 100 ));
		}, 100);
		return () => { clearInterval(timer) };
	}, []);

	return (
		<div className='App'>
			<br/>
			<meta http-equiv="Refresh" content="10; url=https://adminportal.labora.tech/" />
			<h3>Labora: App Varejo</h3>
			<br/>
			<CircularProgressWithLabel value={progress} />
			<h3>Redirecionando para Admim Portal</h3>
			<br/>
			<Link href="https://old-app-varejo.labora.tech/" variant="body2">Para acessar o AppVarejo antigo, clique aqui</Link>
			<br/>
			<br/>
			<LinearProgress sx={{ width: '50%', position: 'relative', display: 'inline-flex' }} variant="determinate" value={progress} />
			<br/>
			{/* <BorderLinearProgress sx={{ width: '75%', position: 'relative', display: 'inline-flex' }} variant="determinate" value={progress} /> */}
		</div>
		
	);
};

CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
	return(
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0, left: 0, bottom: 0, right: 0,
					position: 'absolute', display: 'flex', alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
	},
}));
